import imageUrlBuilder from '@sanity/image-url';
import { dataset } from 'lib/SanityClient';

import { Image } from 'sharedTypes';

const projectId =
  process.env.REACT_APP_SANITY_PROJECT_ID ||
  process.env.NEXT_PUBLIC_SANITY_PROJECT_ID;

const imageBuilder = (
  image: Image,
  width: number,
  quality: number,
  dpr: number,
  isHighDefImage: boolean
): string => {
  if (!projectId) {
    console.error('Sanity project id is missing');
    return '';
  }

  const builder = imageUrlBuilder({
    projectId,
    dataset,
  });

  const imageAsImageObject = {
    asset: {
      _ref: image.id,
    },
    crop: image.crop,
    hotspot: image.hotspot,
  };

  const isGif = image.id.toLowerCase().endsWith('gif');
  let url = '';

  if (isGif) {
    url = builder.image(imageAsImageObject).url() || '';
  } else if (isHighDefImage) {
    url =
      builder
        .image(imageAsImageObject)
        .width(width)
        .dpr(dpr)
        .auto('format')
        .url() || '';
  } else {
    url =
      builder
        .image(imageAsImageObject)
        .width(width)
        .quality(quality)
        .dpr(dpr)
        .auto('format')
        .url() || '';
  }

  // This is not a production image URL, return it as is
  if (
    dataset !== 'production' ||
    !url.includes(`https://cdn.sanity.io/images/${projectId}/${dataset}/`)
  ) {
    return url;
  }

  // If this is a production image, make it KeyCDN instead
  return convertSanityURLToImageCDNUrl(url);
};

export const convertSanityURLToImageCDNUrl = (url: string) =>
  url.replace(
    `cdn.sanity.io/images/${projectId}`,
    'tablet-mag-images.b-cdn.net'
  );

export default imageBuilder;
