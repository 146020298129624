import { RouteMap } from 'constants/RouteMap';

import { AuthorLink } from 'sharedTypes';

type AuthorLinkProps = Pick<AuthorLink, 'slug' | 'legacySlug'>;

const generateAuthorUrl = (author: AuthorLinkProps): string => {
  return `${RouteMap.CONTRIBUTOR.base}${
    author.slug || author.legacySlug || ''
  }`;
};

export default generateAuthorUrl;
